<template>
    <div class="footer">
        <div class="home_footer">
            <div class='title'>{{ footerTitle }}</div>
            <router-link target="_blank" to='/contact'>
              <div class="button">{{ footerButton }}</div>
            </router-link>
        </div>
        <div class="clients" v-if="$route.name === 'AIGC' || $route.name === 'AIPPT'">
            <div class="clients_title">合作客户</div>
            <div class="clients_content" v-if="$route.name === 'AIGC'">
              <img src="https://dam-static.oss-cn-beijing.aliyuncs.com/qiye1.0/AIGC/custom/kehu_h5.png" alt="" class="kehu_left">
              <img src="https://dam-static.oss-cn-beijing.aliyuncs.com/qiye1.0/AIGC/custom/kehu_h5.png" alt="" class="kehu_right">
            </div>
            <div class="clients_wrap" v-else>
              <img src="https://dam-static.oss-cn-beijing.aliyuncs.com/qiye1.0/home/logo.png" alt="" class="kehu_left">
              <img src="https://dam-static.oss-cn-beijing.aliyuncs.com/qiye1.0/home/logo.png" alt="" class="kehu_right">
            </div>
        </div>
    <div class="footerNav">

        <div class="menu">
            <div class="code">
                <img :src="`https://file.isheji.com/isheji/static/qiye/image/footer/code.png`">
                <div class="text">
                    <section>关注爱设计公众号</section>
                    <section>添加客服微信</section>
                    <section>免费体验内容中台</section>
                </div>
            </div>
            <div class="list">
                <div class="item">
                    <div class="header">产品</div>
                    <router-link to='/supply' class="link">智能创意供给</router-link>
                    <router-link to='/output' class="link">智能内容生产</router-link>
                    <router-link to='/manage' class="link">智能内容管理</router-link>
                    <router-link to='/fission' class="link">智能内容分发</router-link>
                    <router-link to='/data' class="link">智能数据管理</router-link>
                    <router-link to='/platform' class="linkOn">开放平台</router-link>
                </div>
                <div class="item">
                    <div class="header">场景解决方案</div>
                    <router-link to='/private' class="link">私域内容营销</router-link>
                    <router-link to='/purchase' class="link">创意资源采买</router-link>
                    <router-link to='/brand' class="link">品牌资产管理</router-link>
                    <router-link to='/marketing' class="link">社交媒体营销</router-link>
                    <router-link to='/about' class="linkOn">关于我们</router-link>
                </div>
                <div class="item">
                    <div class="header">行业解决方案</div>
                    <router-link to='/car' class="link">汽车行业解决方案</router-link>
                    <router-link to='/retail' class="link">新零售行业解决方案</router-link>
                    <router-link to='/house' class="link">家居行业解决方案</router-link>
                    <router-link to='/chain' class="link">连锁经营行业解决方案</router-link>
                    <router-link to='/finance' class="link">金融行业解决方案</router-link>
                    <router-link to='/education' class="link">教育行业解决方案</router-link>
                    <router-link to='/network' class="link">互联网行业解决方案</router-link>
                </div>
                <div class="item">
                    <div class="header">客户案例</div>
                     <router-link :to="{path:'/detail',query:{id:item.id}}" v-for="item in footerList" class="link" :key="item.id">{{item.subtitle}}</router-link>

                </div>
            </div>
        </div>
        <div class="copyRight">
            <div class="text">
                <span>咨询热线: 400-0530-290</span>
                <span>邮箱: zhiheng@isheji.com</span>
                <span>地址: 北京市朝阳区广渠路3号院竞园5B</span>
            </div>
            <div class="text">Copyright©北京饼干科技有限公司京ICP备18055658号-3</div>
        </div>
    </div>
    </div>
</template>
<script>
import { getFooterList } from "@/config/axios/new_api";
  export default {
    data() {
      return {
        footerList:[]
      };
    },
    computed: {
      footerTitle() {
        let title = ''
        switch (this.$route.name) {
          case 'AIGC':
            title = '为企业高效释放AI创意生产力'
            break
          case 'AIPPT':
            title = '申请合作，即刻接入Ai生成PPT能力'
            break
          default:
            title = '即刻体验爱设计内容中台，让内容营销数字化转型快人一步！'
            break
        }
        return title
      },
      footerButton() {
        let btnText = ''
        switch (this.$route.name) {
          case 'AIGC':
          case 'AIPPT':
            btnText = '立即咨询'
            break
          default:
            btnText = '立即体验'
            break
        }
        return btnText
      }
    },
    created(){
        getFooterList().then((res)=>{
            if(res.data.status == 1){
                this.footerList = res.data.data;

            }
        })
    }

  }
</script>
<style lang="scss" scoped>
.footer{
        position: relative;
}
  .home_footer{
      background-image: url('https://file.isheji.com/isheji/static/qiye/image/home/footer.png');
      width: 100%;
      min-width: 1400px;
      height: 312px;
      background-position-x: center;
      display: flex;
      background-size: cover;
      flex-direction: column;
      align-items: center;
      .title{
        padding-top: 86px;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 45px;
        margin-bottom: 48px;
      }
      .button{
        width: 128px;
        height: 48px;
        border-radius: 4px;
        border: 1px solid #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        cursor: pointer;
        &:hover{
          background: #FFFFFF;
          border-radius: 4px;
          border: 1px solid #FFFFFF;
          color: #3B3946;
        }
      }
  }
  .clients {
      width: 100%;
      padding: 72px 0;
      .clients_title {
          min-width: 1200px;
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #3B3946;
          line-height: 45px;
          margin-bottom: 32px;
          text-align: center;
      }
      .clients_content {
          min-width: 1200px;
          height: 452px;
          width: 1140px;
          margin: 0 auto;
          position: relative;
          overflow: hidden;
          &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 9;
            background-image: url('https://dam-static.oss-cn-beijing.aliyuncs.com/qiye1.0/AIGC/mengceng.png');
            background-size: contain;
            background-repeat: no-repeat;
          }
          img {
             height: 100%;
             object-fit: contain;
             position: absolute;
             top: 0;
             max-width: none;
          }
          .kehu_left {
            transform: translateX(0);
            animation: swiper-left 60s linear infinite;
          }
          .kehu_right {
            transform: translateX(100%);
            animation: swiper-right 60s linear infinite;
          }
          @keyframes swiper-left {
            0% {
                transform: translateX(0);
            }
            to {
                transform: translateX(-100%);
            }
          }
          @keyframes swiper-right {
            0% {
                transform: translateX(100%);
            }
            to {
                transform: translateX(0);
            }
          }
      }
    .clients_wrap {
      width: 1240px;
      margin: 0 auto;
      height: 284px;
      position: relative;
      overflow: hidden;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 9;
        background-image: url('https://dam-static.oss-cn-beijing.aliyuncs.com/qiye1.0/solution/solution_logo_mark.png');
        background-size: 1240px 384px;
        background-repeat: no-repeat;
      }
      img {
        height: 284px;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 0;
      }
      .kehu_left {
        transform: translate(0, -50%);
        animation: swiper-left1 60s linear infinite;
      }
      .kehu_right {
        transform: translate(calc(100% - 1px), -50%);
        animation: swiper-right1 60s linear infinite;
      }
      @keyframes swiper-left1 {
        0% {
          transform: translate(0, -50%);
        }
        to {
          transform: translate(-100%, -50%);
        }
      }
      @keyframes swiper-right1 {
        0% {
          transform: translate(calc(100% - 1px), -50%);
        }
        to {
          transform: translate(-1px, -50%);
        }
      }
    }
  }
    .footerNav{
        width: 100%;
        min-width: 1400px;
        height: 462px;
        padding-top: 80px;
        display: flex;
        align-items: center;
         background: #1C1C23;
         flex-direction: column;
        .menu{
           width: 1200px;
           display: flex;
            height: 300px;
            padding-bottom: 32px;
                border-bottom: 1px solid rgba(110,108,118,0.5);
           .code{
                width: 180px;
                height: 234px;
                background: #FFFFFF;
                border-radius: 4px;
                padding: 18px;
                margin-right: 75px;
                img{
                    width: 144px;
                    height: 144px;
                }
                .text{
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #3B3946;
                    line-height: 17px;
                    text-align: center;
                }
           }
           .list{
               display: flex;
               flex: 1;
               justify-content: space-between;
               font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #929296;
                line-height: 20px;
                .item{
                    display: flex;
                    flex-direction: column;
                    .header{
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 22px;
                        margin-bottom: 24px;
                    }
                    .link{
                        margin-bottom: 16px;
                        color: #929296;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-break: break-all;
                        max-width: 300px;
                        cursor: pointer;
                        line-height: 16px;
                        &:hover{
                            color: #fff;
                        }
                    }
                    .linkOn{
                        color: #929296;
                        font-size: 16px;
                        cursor: pointer;
                         &:hover{
                            color: #fff;
                        }
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        line-height: 22px;
                        flex: 1;
                        display: flex;
                        align-items: flex-end;
                    }
                }
           }
        }
        .copyRight{
            display: flex;
             width: 1200px;
            justify-content: space-between;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #929296;
            line-height: 20px;
            align-items: center;
            flex: 1;

            span {
                margin-right: 24px;
            }
        }
    }

</style>
