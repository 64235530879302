<template>
  <div id="header" class="header">
    <div class="logo" @click="goIndex">
      <img src="https://dam-static.oss-cn-beijing.aliyuncs.com/qiye1.0/home/header_logo.png" alt="">
    </div>
    <div class="menu">
      <div class="menu-box" @mouseleave="leaveTopNav">
        <template v-for="(firstItem, index) in menuData">
          <el-dropdown
            v-if="firstItem.childs"
            :key="index"
            placement="bottom"
            @visible-change="showMenu($event, index)"
            @command="goRouter"
          >
            <span
              class="el-dropdown-link menu-item"
              :class="activeIndex == index ? 'menu-active' : ''"
              @mouseenter="activeIndex = index"
            >
              {{ firstItem.name
              }}<i
                :class="
                  firstItem.hasTrigger
                    ? 'el-icon-arrow-up'
                    : 'el-icon-arrow-down'
                "
                class="el-icon--right"
              ></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="topNav">
              <div class="second-box">
                <el-dropdown-item
                  v-show="!secondItem.childs"
                  v-for="secondItem in firstItem.childs"
                  :key="secondItem.id"
                  :command="secondItem"
                  >{{ secondItem.name }}</el-dropdown-item
                >
              </div>
              <div class="third-box">
                <div
                  class="third-item"
                  v-show="secondItem.childs"
                  v-for="secondItem in firstItem.childs"
                  :key="secondItem.id"
                >
                  <el-dropdown-item disabled>{{
                    secondItem.name
                  }}</el-dropdown-item>
                  <el-dropdown-item
                    v-for="thirdItem in secondItem.childs"
                    :key="thirdItem.id"
                    :command="thirdItem"
                    >{{ thirdItem.name }}</el-dropdown-item
                  >
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <template v-if="firstItem.usePicture">
            <div
              :class="`menu_item_pic logo_${firstItem.name}`"
              @click="goRouter(firstItem)"
              @mouseenter="activeIndex = index"
              :key="index"
            >
              <img :src="(activeIndex === index) ? firstItem.activeSrc : firstItem.src" alt="">
            </div>
          </template>
          <template v-else>
            <div
              v-if="!firstItem.childs"
              class="menu-item"
              :class="activeIndex == index ? 'menu-active' : ''"
              @click="goRouter(firstItem)"
              @mouseenter="activeIndex = index"
              :key="index"
            >
              {{ firstItem.name }}
            </div>
          </template>
        </template>
      </div>
    </div>
    <div class="info" v-if="!loginInfo">
      <div class="contact" style="margin-right: 10px" @click="goContact">
        联系我们
      </div>
      <div class="contact" @click="goLogin">注册登录</div>
    </div>
    <div class="info figure" v-if="loginInfo">
      <div class="contact" style="margin-right: 24px" @click="goCompany">
        进入企业版
      </div>
      <el-dropdown size="mini" trigger="click" @command="userInfoCommand">
        <el-avatar :size="45" split-button type="primary" :src="circleUrl">
        </el-avatar>
        <el-dropdown-menu slot="dropdown" class="avator-info">
          <el-dropdown-item>{{
            loginInfo && loginInfo.nick_name
          }}</el-dropdown-item>
          <el-dropdown-item command="setting-user">
            <i class="iconfont icon-wode"></i>
            <span>账号设置</span>
          </el-dropdown-item>
          <el-dropdown-item
            command="setting-safe"
            v-if="!current_team_info.corp_id"
          >
            <i class="iconfont icon-zhanghaoanquan"></i>
            <span>账号安全</span>
          </el-dropdown-item>
          <el-dropdown-item command="setting-auth">
            <i class="iconfont icon-Authorize"></i>
            <span>授权记录</span>
          </el-dropdown-item>
          <el-dropdown-item command="logout">
            <i class="iconfont icon-tuichudenglu"></i>
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { getTeam } from "@/config/axios/new_api";
import tokenFunc from "@/config/static/token";
export default {
  data() {
    return {
      teamList: [],
      activeIndex: 0,
      menuData: [
        {
          id: 1,
          name: "首页",
          router: "/",
        },
        {
          id: 2,
          name: "ConTech",
          router: "/contech",
        },
        {
          id: 3,
          name: "产品",
          router: "/product",
          childs: [
            {
              id: 8,
              name: "智能创意供给",
              router: "/supply",
            },
            {
              id: 9,
              name: "智能内容生产",
              router: "/output",
            },
            {
              id: 10,
              name: "智能内容管理",
              router: "/manage",
            },
            {
              id: 11,
              name: "智能内容分发",
              router: "/fission",
            },
            {
              id: 12,
              name: "智能数据管理",
              router: "/data",
            },
          ],
          hasTrigger: false,
        },
        {
          id: 4,
          name: "解决方案",
          router: "/solution",
          childs: [
            {
              id: 13,
              name: "场景解决方案",
              childs: [
                {
                  id: 14,
                  name: "私域内容营销",
                  router: "/private",
                },
                {
                  id: 15,
                  name: "创意资源采买",
                  router: "/purchase",
                },
                {
                  id: 16,
                  name: "品牌资产管理",
                  router: "/brand",
                },
                {
                  id: 17,
                  name: "社交媒体营销",
                  router: "/marketing",
                },
              ],
            },
            {
              id: 18,
              name: "行业解决方案",
              childs: [
                {
                  id: 23,
                  name: "汽车行业解决方案",
                  router: "/car",
                },
                {
                  id: 22,
                  name: "新零售行业解决方案",
                  router: "/retail",
                },
                 {
                  id: 25,
                  name: "家居行业解决方案",
                  router: "/house",
                },
                {
                  id: 21,
                  name: "连锁经营行业解决方案",
                  router: "/chain",
                },

                {
                  id: 19,
                  name: "金融行业解决方案",
                  router: "/finance",
                },
                {
                  id: 20,
                  name: "教育行业解决方案",
                  router: "/education",
                },
                {
                  id: 24,
                  name: "互联网行业解决方案",
                  router: "/network",
                }
              ],
            },
          ],
          hasTrigger: false,
        },
        {
          id: 30,
          name: "AIGC",
          router: "/aigc",
          usePicture: true,
          src: 'https://dam-static.oss-cn-beijing.aliyuncs.com/qiye1.0/AIGC/AIGC.png',
          activeSrc: 'https://dam-static.oss-cn-beijing.aliyuncs.com/qiye1.0/AIGC/AIGC_active.png'
        },
        {
          id: 31,
          name: "AIPPT",
          router: "/aippt",
          usePicture: true,
          src: 'https://dam-static.oss-cn-beijing.aliyuncs.com/qiye1.0/AIPPT/logo.png',
          activeSrc: 'https://dam-static.oss-cn-beijing.aliyuncs.com/qiye1.0/AIPPT/logo_active.png'
        },
        {
          id: 5,
          name: "开放平台",
          router: "/platform",
        },
        {
          id: 18,
          name: "研究报告",
          router: "/report",
        },
        {
          id: 6,
          name: "客户案例",
          router: "/case",
        },
        {
          id: 7,
          name: "关于我们",
          router: "/about",
        }
      ],
    };
  },
  computed: {
    loginInfo() {
      return tokenFunc.getToken() ? this.$store.getters.loginInfo : null;
    },
    logoForm() {
      return this.$store.getters.logoForm;
    },
    circleUrl() {
      return (
        (this.loginInfo && this.loginInfo.image_url) ||
        require("@/assets/image/mobile_head.png")
      );
    },
    current_team_info() {
      return this.$store.getters.currentTeam;
    },
  },
  watch: {
    $route() {
      this.leaveTopNav();
      document.body.scrollIntoView();
    },
  },
  created() {
    window.onscroll = function () {
      var sl = -Math.max(
        document.body.scrollLeft,
        document.documentElement.scrollLeft
      );
      if(document.getElementById("header")){
        document.getElementById("header").style.left = sl + "px"; //重新设置横向滚动条位置
      }

    };
    this.leaveTopNav();
    if (tokenFunc.getToken()) {
      this.getTeamList();
    }
  },
  methods: {
    getTeamList() {
      getTeam().then((res) => {
        if (res && res.data) {
          this.teamList = res.data;
          this.$store.commit("teamList", res.data);
        }
      });
    },
    showMenu(key, index) {
      this.menuData[index].hasTrigger = key;
    },
    goRouter(item) {
      if (this.$route.path != item.router) {
        this.$router.push(item.router);
      }
    },
    goLogin() {
      this.$router.push("/login" + window.location.search);
    },
    goContact() {
      let route = this.$router.resolve({ path: "/contact" });
      window.open(route.href, "_blank");
    },
    leaveTopNav() {
      if (this.$route.meta.name == "home") {
        this.activeIndex = 0;
      }
      this.menuData.forEach((element, index) => {
        if (element.router == "/" + this.$route.meta.name) {
          this.activeIndex = index;
        }
      });
    },
    userInfoCommand(info) {
      if (info === "logout") {
        var access_token = tokenFunc.getToken();
        if (access_token) {
          loginSdk
            .requestAction("logout", { token: access_token })
            .then((res) => {
              if (res.code == 0) {
                window.sessionStorage.clear();
                this.$store.commit("loginInfo", null);
                this.staticFunc.toHref(process.env.VUE_APP_BASELogout);
                return;
              }
            })
            .catch((error) => {});
        } else {
          window.sessionStorage.clear();
          this.$store.commit("loginInfo", null);
          this.staticFunc.toHref(process.env.VUE_APP_BASELogout);
        }
      } else if (
        info === "setting-safe" ||
        info === "setting-user" ||
        info === "setting-auth"
      ) {
        this.$router.push({
          name: "User",
          query: { info: info.split("-")[1] },
        });
      }
    },
    goCompany() {
      if (!tokenFunc.getToken()) {
        // this.showLogin = true;
        let route = this.$router.resolve({ path: "/login.html" });
        window.open(route.href, "_blank");
        return;

      } else {
        if (
          this.teamList &&
          this.teamList.length > 0 &&
          this.loginInfo.current_team_id == 0
        ) {
          this.$router.push({ name: "Team" });
        } else if (this.loginInfo.current_team_id != 0) {
          let domain = this.loginInfo.current_team_domain;
          if (process.env.VUE_APP_RealmName) {
            // 配置域名
            domain = "https://" + domain.split(".")[0] + `.${process.env.VUE_APP_RealmName}`;
            window.location.href = domain
          }else{
             window.location.href = "https://" + this.loginInfo.current_team_domain;
          }
          // this.staticFunc.toHref(domain);


        } else {
          let route = this.$router.resolve({ path: "/contact" });
          window.open(route.href, "_blank");
        }
      }
    },
    goIndex() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  padding: 0 24px;
  height: 60px;
  align-items: center;
  background-color: #25252d;
  width: 100%;
  min-width: 1400px;
  .logo {
    width: 138px;
    height: 37px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .menu {
    flex: 1;
    display: flex;
    justify-content: center;
    .menu-box {
      display: flex;
      .menu-item {
        height: 100%;
        padding: 0 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #929296;
        line-height: 60px;
        cursor: pointer;
        &:hover {
          color: #ffffff;
          .el-icon-arrow-down {
            transition: transform 0.3s;
            transform: rotate(180deg);
          }
        }
      }
      .menu-active {
        color: #fff;
      }
      .menu_item_pic {
        width: 94px;
        height: 60px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .logo_AIPPT {
        width: 112px;
      }
    }
  }
  .contact {
    width: 100px;
    height: 40px;
    background: #5a2df5;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
  }
  .figure {
    cursor: pointer;
    float: left;
    margin-top: 8px;
    > img {
      @include wh(45px, 45px);
      border-radius: 50%;
    }
  }
}
</style>
<style lang="scss">
.topNav {
  background-color: #25252d !important;
  border: 0;
  box-shadow: none !important;
  .popper__arrow {
    display: none;
  }
}
.el-popper[x-placement^="bottom"] {
  margin-top: 20px;
  padding: 8px 0;
  border-radius: 0;
  .el-dropdown-menu__item {
    text-align: center;
    line-height: 50px;
  }
  .second-box {
    .el-dropdown-menu__item {
      width: 156px;
      color: #ffffff;
    }
  }
  .third-box {
    display: flex;
    .third-item {
      flex: 1;
      .el-dropdown-menu__item {
        text-align: left;
        padding: 0 36px;
        width: 245px;
        color: #ffffff;
        line-height: 50px;
      }
      .el-dropdown-menu__item.is-disabled {
        cursor: default;
        color: #ac81ff;
        pointer-events: none;
      }
    }
  }
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #25252d;
  color: #929296 !important;
}
.info {
  display: flex;
}
.manage-info.el-popper {
  @include wh(190px, auto);
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
  margin-top: 0 !important;
  &.el-popper .popper__arrow {
    display: none;
  }
  li {
    @include wh(100%, 48px);
    line-height: 48px;
    font-size: 14px;
    color: #333;
    &:hover,
    &.active {
      background: #f7f7f7;
      color: #333;
    }
  }
}
.avator-info {
  @include wh(220px, auto);
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  font-size: 14px;
  padding: 0 !important;
  margin-top: 0 !important;
  text-align: left !important;
  &.el-popper .popper__arrow {
    display: none;
  }
  > li:first-child {
    @include wh(100%, 60px);
    // background: linear-gradient(213deg, #7b788d 0%, #3b3946 100%);
    background-image: url("~@/assets/image/Oval.png");
    background-size: 100% 100%;
    border-radius: 8px 8px 0px 0px;
    line-height: 60px;
    color: #fff;
    margin-bottom: 8px;
    font-size: 14px;
    &:hover {
      color: #fff;
    }
  }
  > li:not(:first-child) {
    @include wh(100%, 48px);
    line-height: 48px;
    font-size: 14px;
    color: #333;
    &:hover {
      background: #f7f7f7;
      color: #333;
    }
  }
  li {
    display: flex;
    align-items: center;
  }
}
</style>
