import axios from "axios";
let httpServer = axios.create({
  baseURL: "/",
  timeout: 30000,
  headers: {
    "Cache-Control": "no-cache",
  },
});
export function getHmapi() {
  
    httpServer.post('/ocpcapi/api/uploadConvertData', {
      token: '49PN2wZ3HOfxjgT3cCGWlAgOYGUnOeIo@MyGAS7rKRwpXNRMhhiQeQW6AG1o8V2rW',
      conversionTypes: [
        {
          "logidUrl": window.location.href,
          "newType": 92,
      
        }]  
      }
    )
    .then(function (response) {
     
      console.log(response);
     
    })
  }
