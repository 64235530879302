<template>
  <div id="login-app">
    <CHeader v-if="$route.meta.header" />
    <router-view />
    <Footer v-if="$route.meta.footer" />
    <rightNav v-if="!isDialog"></rightNav>
  </div>
</template>
<script>
import CHeader from "@/components/topNav.vue";
import Footer from "@/components/footerNav.vue";
import rightNav from "@/components/rightNav.vue";
import staticFunc from "@/config/static/func";
import tokenFunc from "@/config/static/token";
import { Member } from "@/config/axios/new_api";
export default {
  components: { CHeader, Footer, rightNav },
  created () {
    if (staticFunc.GetQueryString("type") == "logout") {
      // localStorage.removeItem("authorization");
    }
    this.initUtmData();
    if (tokenFunc.getToken()) {
      this.getCurrentTeam();
    }
  },
  computed: {
    isDialog () {
      return this.$route.query.type === "dialog" || window.location.href.indexOf("enterprise-design-v1") !== -1 || this.$route.path === "/maintain" || this.$route.name === "reportImage";
    },
  },
  methods: {
    initUtmData () {
      // 获取utm等信息
      if (staticFunc.GetQueryString("utm_type") || staticFunc.GetQueryString("utm_source")) {
        var utmParams = {
          utm_type: decodeURIComponent(staticFunc.GetQueryString("utm_type")), // 渠道类型
          utm_source: decodeURIComponent(staticFunc.GetQueryString("utm_source")), // 广告来源
          utm_plan: decodeURIComponent(staticFunc.GetQueryString("utm_plan")) || decodeURIComponent(staticFunc.GetQueryString("utm_medium")), // 广告计划
          utm_page: decodeURIComponent(staticFunc.GetQueryString("utm_page")), // 落地页来源
          utm_unit: decodeURIComponent(staticFunc.GetQueryString("utm_unit")) || decodeURIComponent(staticFunc.GetQueryString("utm_campaign")), // 广告单元
          utm_keyword: decodeURIComponent(staticFunc.GetQueryString("utm_keyword")), // 关键词
        };
        localStorage.removeItem("utmParams");
        localStorage.setItem("utmParams", JSON.stringify(utmParams));
      }
    },
    // 获取当前团队信息
    async getCurrentTeam () {
      let res = await Member.getCurrentTeam();
      if (res.status == 1) {
        this.$store.commit("changeCurrentTeam", res.data);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/css/edit-ele.scss";
@import "@/assets/css/common.scss";

// 处理落地页弹出el-dialog影响body布局
html,
body {
  padding: 0 !important;
  // overflow: auto !important;
}

#login-app {
  height: 100%;
}

#nb-frame-chat-box {
  right: 100px !important;
}

.embed-components {
  //opacity: 0 !important;
  //right: 30px !important;
  //top: 60% !important;
  width: 60px !important;
  pointer-events: none;
  bottom: 10px !important;
  right: 25px !important;
  top: unset !important;
  z-index: 99 !important;
  background-color: transparent !important;
  color: transparent !important;
  box-shadow: none !important;
  .embed-components-item {
    background-color: transparent !important;
    color: transparent !important;
    span {
      &:first-child {
        opacity: 0;
      }
    }
  }
}</style>
