import "@/assets/css/common.scss";
import "@/assets/css/style/theme/index.css";
import "@/assets/icon/font/iconfont.css";
import staticFunc from "@/config/static/func";
import store from "@/store";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueCropper from "vue-cropper";

import 'swiper/dist/css/swiper.css'; 
import VueAwesomeSwiper from 'vue-awesome-swiper';
Vue.use(VueAwesomeSwiper)
Vue.use(VueCropper);
Vue.config.productionTip = false;
Vue.prototype.staticFunc = staticFunc;
// 错误提示信息
Vue.prototype.catchMessage = function(info){
  var error_type = typeof info;
	var msg;
	if(error_type == "string"){
		msg = info
	}
	if(error_type == "object"){
		if(info.data){
			msg = info.data.msg;
			if(info.data.code){
				msg += `(${info.data.code})`;
			}
		}else{
			msg = info.msg || info.error || info.message
		}
	}
  Vue.prototype.$message.error(msg);
}
Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#login-app");
