<template>
  <div class="rightNav">
      <div class="navItem" @click.stop="openService">
        <div class="img">
          <img class="imgDefault" src="https://file.isheji.com/isheji/static/qiye/image/home/rightNav1.png" alt="">
          <img class="imgHover" src="https://file.isheji.com/isheji/static/qiye/image/home/rightNav1Hover.png" alt="">
        </div>
        <div class="text">
          在线咨询
        </div>
      </div>
      <div class="navItem" @click.stop="openSubmit">
        <div class="img">
          <img class="imgDefault" src="https://file.isheji.com/isheji/static/qiye/image/home/rightNav5.png" alt="">
          <img class="imgHover" src="https://file.isheji.com/isheji/static/qiye/image/home/rightNav5Hover.png" alt="">
        </div>
        <div class="text">
          提交信息
        </div>
      </div>
      <div class="navItem" @click.stop="openCode">
        <div class="img">
          <img class="imgDefault" src="https://file.isheji.com/isheji/static/qiye/image/home/rightNav2.png" alt="">
          <img class="imgHover" src="https://file.isheji.com/isheji/static/qiye/image/home/rightNav2Hover.png" alt="">
        </div>
        <div class="text">
          关注微信
        </div>
        <!-- <div id="wl-qrcode">
        </div> -->
      </div>
      <div class="navItem" onclick="trial()">
        <div class="img">
          <img class="imgDefault" src="https://file.isheji.com/isheji/static/qiye/image/home/rightNav3.png" alt="">
          <img class="imgHover" src="https://file.isheji.com/isheji/static/qiye/image/home/rightNav3Hover.png" alt="">
        </div>
        <div class="text">
          申请试用
        </div>
      </div>
      <div class="navItem" onclick="toTop()" v-if="isShowTop">
        <div class="img" style="marginTop: 28px;">
          <img class="imgDefault" src="https://file.isheji.com/isheji/static/qiye/image/home/rightNav4.png" alt="">
          <img class="imgHover" src="https://file.isheji.com/isheji/static/qiye/image/home/rightNav4Hover.png" alt="">
        </div>

      </div>

    </div>
</template>
<script>
import {getHmapi} from '@/config/axios/hmapi';
  // import YSF from '@neysf/qiyu-web-sdk';
export default {
  data(){
    return {
      isShowTop: true, // 展示回到顶部按钮
    }
  },
  mounted() {
    // YSF.init('dc5d8f911b85daf1a997184fedbd5ec1',{});
  },
  watch:{
      '$route'(to){
        if(to.name === 'Login') {
            this.isShowTop = false
            return
        }
        this.isShowTop = true
      }
  },
  methods: {
    openSubmit() {
      document.querySelectorAll(".embed-popover")[2].click()
    },
    trial(){
      window.open(window.location.origin+'/contact', '_blank');
    },
    toTop(){
      document.body.scrollIntoView({behavior:'smooth'})
    },
    openCode(){
      document.querySelectorAll(".embed-popover")[1].click()
    },
    openService() {
      document.querySelectorAll(".embed-popover")[0].click()
      getHmapi()
    },

  },
};
</script>
<style lang="scss">


</style>
